import { Component, OnInit } from '@angular/core';
import { config } from 'src/app/constants/config';
import { UserService } from 'src/app/services/user.service';
import { navItems as navItemsMenu } from '../../_nav';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import { INavData } from '@coreui/angular';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Router } from '@angular/router';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  public sidebarMinimized = false;
  // public navItems = navItems;
  public navItems: BehaviorSubject<any> = new BehaviorSubject([]);
  currentUser:any;
  profile_image: string = "";
  mediaBase:string= config.media_path;
  current_lang:string = 'es';
  available_langs = ['es', 'gb'];

  constructor(
    private userService: UserService,
    private translate: TranslateService,
    private router: Router
  ) {
    this.currentUser = this.userService.getUser();
    this.current_lang = localStorage.getItem('lang') || 'es';
    //this.profile_image = this.currentUser.profile_image.length>0 ? this.currentUser.profile_image : 'nouser.jpg';
    
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translateNavItems();
    });
    
    this.translateNavItems();
   }

  translateNavItems() {
    let navItemsClone = JSON.parse(JSON.stringify([...navItemsMenu]));
    navItemsClone.map((nav:INavData, index:any) => {      
      const translation = this.translate.instant(String(nav.name));
      navItemsClone[index].name = translation;
    });
    this.navItems.next(navItemsClone);// = navItemsClone;
  }

  ngOnInit(): void {
  }

  toggleMinimize(e:any) {
    this.sidebarMinimized = e;
  }

  logout = () => {
    localStorage.removeItem("token");
    location.href = config.base_url
  }

  availableLangs() {
    return this.available_langs.filter(l => l != this.current_lang);
  }

  changeLang(lang: string) {    
    localStorage.setItem('lang', lang);
    this.current_lang = lang;
    this.translate.use(lang);
  }
}
