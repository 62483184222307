<app-header [navbarBrandRouterLink]="['/users']" [fixed]="true"
  [navbarBrandFull]="{src: 'assets/images/logo.png', width: 110, alt: 'NFT Marketplace'}" [sidebarToggler]="'lg'"
  [asideMenuToggler]="false" [mobileAsideMenuToggler]="false" style="background-color: silver">

  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" 
        dropdownToggle (click)="false">
        <span class="flag-icon flag-icon-{{current_lang}}"></span>
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <a class="dropdown-item" href="javascript:void(0)" (click)="changeLang(lang)"
          *ngFor="let lang of availableLangs()" style="height: 30px;padding: 5px 15px;">
          <span class="flag-icon flag-icon-{{lang}}"></span>
          <div style="padding-left: 25px;position: relative; top: -21px;">{{lang | translate}}</div>
        </a>
      </div>
    </li>
  </ul>
  <ul class="nav navbar-nav">
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"
        dropdownToggle (click)="false">
        <img [src]="mediaBase + '/images/user/'+profile_image" class="img-avatar" alt="admin@bootstrapmaster.com" />
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <div class="dropdown-header"><strong translate>Settings</strong></div>
        <a class="dropdown-item" href="javascript:void(0)" [routerLink]="'/users/edit/'+currentUser.user_id"><i
            class="fa fa-user"></i> {{'Edit Profile'|translate}}</a>
        <a class="dropdown-item" href="javascript:void(0)" (click)="logout()"><i class="fa fa-lock"></i>
          {{'Logout'|translate}}</a>
      </div>
    </li>
  </ul>
</app-header>
<div class="app-body">
  <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized"
    (minimizedChange)="toggleMinimize($event)">
    <app-sidebar-nav [navItems]="navItems | async" [perfectScrollbar] [disabled]="appSidebar.minimized">
    </app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <main class="main">
    <cui-breadcrumb>

    </cui-breadcrumb>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </main>

</div>