import { url } from "inspector";

export const API = {
    base_url: "https://api-juniors.devtop.online",
    //base_url: "http://localhost:5000",
    url_api: "https://apimpjuniors.devtop.online/api/v1",
    //url_api: "https://025srxnp-3050.use2.devtunnels.ms/api/v1",
    user_login: "/user/login",
    user_list: "/user/adminlist",
    user_create: "/user/createuser",
    user_profile: "/user/profile",
    user_update: "/user/updateuser",
    activity_list: "/item/history", // activity list api
    item_list: "/item/list", // item list api
    item_toggle_trending: "/item/toggleTrending", // item list api
    category_list : "/category/fulllist", // category list api
    category_detail : "/category/detail", // category detail list api
    category_create : "/category/add", // category add api
    category_update : "/category/edit", // category edit api
    category_delete : "/category/delete", // category delete api
    category_list_student : "/categoryoptionsestudiante/fulllist", // category list api
    category_detail_student : "/categoryoptionsestudiante/detail", // category detail list api
    category_create_student : "/categoryoptionsestudiante/add", // category add api
    category_update_student : "/categoryoptionsestudiante/edit", // category edit api
    category_delete_student : "/categoryoptionsestudiante/delete",
    category_list_sportsman : "/categoryoptionsdeportista/fulllist", // category list api
    category_detail_sportsman : "/categoryoptionsdeportista/detail", // category detail list api
    category_create_sportsman : "/categoryoptionsdeportista/add", // category add api
    category_update_sportsman : "/categoryoptionsdeportista/edit", // category edit api
    category_delete_sportsman : "/categoryoptionsdeportista/delete",
    offer_list: "/item/offers",
    option_set: "/settings/setoptions",
    option_get: "/settings/getoptions",
    options_get: "/settings/options",
    collection_list:"/collection/fulllist",
    collection_delete: "/collection/deleteAllData",
    collection_update_status: "/collection/updateStatus",
    collection_view:"/collection/detail",
    settings_get: "/settings"
}
