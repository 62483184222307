/*
Project : Cryptotrades
FileName :  user.service.ts
Author : LinkWell
File Created : 21/07/2021
CopyRights : LinkWell
Purpose : This is the file which used to define list and search the item list api functionalities.
*/
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable, BehaviorSubject } from "rxjs";
import { map} from 'rxjs/operators';
import {Common} from '../models/common';
import {API} from '../constants/api'
import {JwtHelperService} from '@auth0/angular-jwt';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  public notifier: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private userSubject: BehaviorSubject<any>;
  public user: Observable<any>;
  constructor(
    private http: HttpClient
  ) {
    this.userSubject = new BehaviorSubject<any>(this.getUser());
    this.user = this.userSubject.asObservable();
   }
  /*
  * This is the function which used to get user session
  */  
  getToken = () => {
    var token = localStorage.getItem('token')
    return token == null ? '' : token;
  }

  refreshUser = () => this.userSubject.next(this.getUser());

  /*
  * This is the function which used to get user
  */
  getUser() {
    var jwtHelper = new JwtHelperService();
    var token = this.getToken()
    if(token.length>0) {
      return jwtHelper.decodeToken(token);
    } else {
      return null;
    }
  }

  getExpirationToken(){
    const jwtHelper = new JwtHelperService();
    const token = this.getToken();
    if (token?.length > 0){
      return jwtHelper.isTokenExpired(token);
    } else {
      return null;
    }
  }

  /*
  * This is the function which used to login user
  */
  loginApi(body:any): Observable<any> {
    let url = `${API.url_api}/adminlogin `;
    return this.http.post<any>(url, body);
  }

  /*
  * This is the function which used to create new user
  */
  createUsers(params:any): Observable<Common> {
    let url = API.base_url + API.user_create;
    return this.http.post<Common>(url, params);
  }

  /*
  * This is the function which used to update user details
  */
  updateUsers(params:any): Observable<any> {
    let url = `${API.url_api}/userStatus`;
    return this.http.put<any>(url, params);
  }

  /*
  * This is the function which used to get particular user details
  */
  viewProfile(wallet:string): Observable<Common> {
    let url = `${API.url_api}/users/${wallet}`;
    return this.http.get<Common>(url);
  }

  /*
  * This is the function which used to get user details
  */
  list(): Observable<any> {
    let url = `${API.url_api}/users`;
    return this.http.get<any>(url);
  }

}
 
