import { Injectable } from '@angular/core';
import {
   HttpInterceptor, HttpHandler, HttpRequest
  } from '@angular/common/http';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private user: UserService, 
    private router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService
) {}

  logoutAction = () => {
    localStorage.removeItem('token');
    this.user.refreshUser();
    // location.href = config.base_url;
    this.router.navigate(['/']);
  };


  /*
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    const authToken = this.user.getToken();

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    if(authToken != '') {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', authToken)
      }); 
      return next.handle(authReq);
    } else {
      const authReq = req.clone(); 
      return next.handle(authReq);
    }


    // send cloned request with header to the next handler.
    
  } */

    intercept(req: HttpRequest<any>, next: HttpHandler) {

      const authToken = localStorage.getItem('token');
  
      const expiredToken = this.user.getExpirationToken();
    
      if (expiredToken === true) {
        this.snackBar.open(this.translate.instant('Session expired, please login again'), '');
            setTimeout(() => {
              this.logoutAction();
            }, 3000);
        this.logoutAction();
        return throwError('Token Expired')
      }
  
      //return next.handle(req);
  
      if(authToken != null) {
        const authReq = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${authToken}`)
        }); 
        return next.handle(authReq);
      } else {
        const authReq = req.clone(); 
        return next.handle(authReq);
      }
      
    }
  
}