<div class="app-body">
    <main class="main d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-md-6 mx-auto">
            <div class="card-group">
              <div class="card p-3">
                <div class="card-body">
                <form [formGroup]="login" (ngSubmit)="onSubmit(login)">
                    <h1 translate>Login</h1>
                    <p class="text-muted" translate>Sign In to your account</p>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="icon-user"></i></span>
                      </div>
                      <input type="text" class="form-control" placeholder="Enter Username" formControlName="username" autocomplete="username" required>
                    </div>
                    <div class="input-group mb-4">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="icon-lock"></i></span>
                      </div>
                      <input type="password" class="form-control" placeholder="Enter Password" formControlName="password" autocomplete="current-password" required>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <button type="submit" class="btn btn-primary px-4" translate>Login</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  