import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Market';

  current_lang:string = 'es';
  available_langs = ['es', 'gb'];

  constructor(
    private translate: TranslateService
  ) {
    this.current_lang = localStorage.getItem('lang') || 'es';
    this.translate.addLangs(this.available_langs);
    this.translate.setDefaultLang('es');
    this.translate.use(this.current_lang);
    console.log('AppComponent ...');
  }
}
