

export const config = {
    site_name: "Market",
    media_path: "https://api-juniors.devtop.online",
    base_url: "https://api-juniors.devtop.online", //"http://localhost:4200"
    frontend_url: "http://localhost:4200"
} 

/*
export const config = {
    site_name: "Market",
    media_path: "http://localhost:5000",
    base_url: "http://localhost:5000", //"http://localhost:4200"
    frontend_url: "http://localhost:4200"
} */